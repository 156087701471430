var
  $this = $('.js-sharing-affix'),
  smoothOffsetBottom,
  smoothOffsetTop,
  $bottomReference,
  initialLeft,
  initialTop,
  resetPositionLeft
;

var computeAffixLeftPosition = function() {
  return $this.offset().left;
};

var onResetAffixLeftPosition = function(event) {
  $this.css({
    left: initialLeft,
    top: initialTop,
  });
};

var initAffix = function() {
  if (!$this.length) {
    return;
  }

  smoothOffsetBottom = $this.data('refineOffsetBottom') ? $this.data('refineOffsetBottom'): 0,
  smoothOffsetTop = $this.data('refineOffsetTop') ? $this.data('refineOffsetTop'): 0,
  $bottomReference = $($this.data('bottomReference')),
  initialLeft = parseInt($this.css('left')),
  initialTop = parseInt($this.css('top')),
  resetPositionLeft = $this.offset().left;

  $this.affix({
    offset: {
      bottom() {
        if (!$bottomReference.length) {
          return null;
        }

        return this.bottom = Math.max($(document).height(), $(window).height()) - ($bottomReference.next().offset().top - smoothOffsetBottom);
      },
      top: $this.offset().top - smoothOffsetTop,
    },
  });

  $this.on('affixed.bs.affix', function(e) {
    $this.css({
      left: resetPositionLeft,
      top: smoothOffsetTop + 'px',
    });
  });
  $this.on('affixed-top.bs.affix', onResetAffixLeftPosition);
  $this.on('affixed-bottom.bs.affix', onResetAffixLeftPosition);
};

initAffix();
